
class TimeoutClass {
    constructor(callback, timeout = 500) {
        this.timeout = timeout;
        this.waitingProcess = null;
        this.callBack = callback;
    }

    async run(...parameters){
        clearTimeout(this.waitingProcess)
        this.waitingProcess = setTimeout(() => {
            if(typeof(this.callBack === 'function')){
                console.log(`run ${this.callBack.name}`);
                this.callBack(...parameters)
            }
        }, this.timeout);
    }
}

module.exports = {
    TimeoutClass
}