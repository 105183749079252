import { profilePrefix } from ".";
import {
  apiUrl, _base_axios_get
} from "..";
  
  
  // const PAGESIZE = 30;
  
  export default function getOrCreateRobotProfile(query = {}) {
    return _base_axios_get(`${apiUrl}/${profilePrefix}/getOrCreateRobotProfile`, query);
  }
  