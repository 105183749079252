import { useLocation } from "@reach/router";
import { InputNumber, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { cloneDeep, set, toNumber } from "lodash";
import get from "lodash.get";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { TimeoutClass } from "../../../class/TimeoutClass";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getOrCreateRobotProfile from "../../../newApi/profile/getOrCreateRobotProfile";
import getProfiles from "../../../newApi/profile/getProfiles";
import getRobotProfiles from "../../../newApi/robots/getRobotProfiles";
import { clearModal, triggerModal } from "../../../redux/actions/app-actions";
import {
  loginSuccessful,
  logoutSuccessful
} from "../../../redux/actions/user-actions";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { exchangePlatform, numberDecimal } from "../../../utilities/startUp";
import Layout from "../../general/components/Layout";
import NewHeader from "../../general/components/NewHeader";
import SelectLanguageModal from "../../setting/components/SelectLanguageModal";
import HighFrequencyForm3 from "../components/HighFrequencyForm3";

// markup
const rounding = false;
const roundDecimal = 2;
const RobotPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [walletLimit, setWalletLimit] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [profile, setProfile] = useState({});
  const [robotProfile, setRobotProfile] = useState({});
  const [platform, setPlatform] = useState();
  const [exchangeAccountBalance, setExchangeAccountBalance] = useState({});
  const [robotProfileId, setRobotProfileId] = useState();
  const [selectVisible, setSelectVisible] = useState(false);

  // let exchangeBalance = get(exchangeAccountBalance, "USDT.free") || 0;
  const accessKey = get(props.user, "accessKey");

  useEffect(() => {
    let query = queryString.parse(location.search);
    setPlatform(get(query, "platform"));
    getData();
    getRobotProfile.run(get(props.user, "user.profile._id"), exchangePlatform);
  }, []);

  useEffect(() => {
    if (platform) {
      // getRobotProfile.run(get(props.user, "user.profile._id"), platform);
    }
  }, [platform, props.user]);

  useEffect(() => {
    if (robotProfileId) {
      // getAccountBalanceAPI.run(robotProfileId);
      getRobotProfilesData.run(robotProfileId);
    }
  }, [robotProfileId]);

  let getRobotProfile = new TimeoutClass(function getRobotProfile(
    profileId,
    usedFor
  ) {
    getOrCreateRobotProfile({
      usedFor: usedFor,
      exchange: usedFor,
      profileId,
    })
      .then((res) => {
        // console.log("pf res", res);
        setRobotProfileId(get(res, "data.robotProfileId"));
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
      });
  });

  

  let getRobotProfilesData = new TimeoutClass(function getRobotProfilesData(
    robotProfileId
  ) {
    // console.log({ robotProfileId });
    // console.log("here");

    getRobotProfiles({
      _id: robotProfileId,
    })
      .then((res) => {
        // console.log("robot profiles data", { res });
        setRobotProfile(get(res, "data[0]"));
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
      });
  },
  1000);

  // console.log("robot profile", robotProfile);
  useEffect(() => {
    if (!editMode) {
      setWalletLimit(get(robotProfile, "robotFundLimit.highFrequency.limit"));
      setCapitalAmount(
        get(profile, `wallet.highFrequency.capital.${exchangePlatform}`) || 0
      );
    }
  }, [robotProfile, editMode]);

  function getData() {
    getProfiles(1, 0, { _id: get(props.user, "user.profile._id") })
      .then((res) => {
        setProfile(get(res, "data[0]") || {});
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmit() {
    let balance = 0;
    let used =
      toNumber(get(robotProfile, "robotFundLimit.highFrequency.used")) || 0;
    // if (walletLimit > exchangeBalance + used) {
    //   message.error(
    //     <>
    //       {/* 币安余额不足  */}
    //       {/* <br />  */}
    //       {t("insufficientBalance", sourceKey.highFrequency)}
    //     </>
    //   );
    //   return;
    // }
    if (capitalAmount <= 0) {
      message.error(
        <>
          {/* 资本金不能小于0 
          <br />  */}
          {t("capitallSmall", sourceKey.highFrequency)}
        </>
      );
      return;
    }
    else if(capitalAmount >walletLimit){
      message.error(
        <>
          {t("lesserThanWallet", sourceKey.highFrequency)}
        </>
      );
      return;
    }
    balance = walletLimit - used;
    let newWalletValue = cloneDeep(get(profile, "wallet"));
    // set(newWalletValue, "highFrequency.balance", balance);
    // set(newWalletValue, "highFrequency.limit", walletLimit);
    set(
      newWalletValue,
      `highFrequency.capital.${exchangePlatform}`,
      capitalAmount
    );
    // set(newWalletValue, `highFrequency.limit.${exchangePlatform}`, walletLimit);

    // enableProfileFundLimit({
    //   profileId: robotProfileId,
    //   limit: walletLimit,
    //   strategy: "highFrequency",
    // })
    //   .then((res) => {
    //     // console.log(res);
    feathers("profiles", accessKey)
      .patch(get(profile, "_id"), {
        wallet: newWalletValue,
      })
      .then((res) => {
        message.success("Changed Limit");
        getRobotProfilesData.run(robotProfileId);
        setProfile(res);
        setEditMode(false);
      });
    // })
    // .catch((err) => {
    //   console.log(err);
    //   message.error(err?.message);
    // });
  }

  return (
    <React.Fragment>
      {/* <RequiredApiCheck
        robotProfileId={robotProfileId}
        onPassed={() => {
          setKeyPassed(true);
        }}
      /> */}
      <Layout>
        <Spin spinning={loading}>
          <NewHeader>
            <div className="rounded-3xl bg-main-color-gradient box-shadow-light px-5 py-3 mx-4">
              {/* <MediaQuery minWidth={601}> */}
              {/* <div className=" text-sm mb-3 relative">
                  高频 High Frequency
                  {type === "3" ? (
                    <span className="inline-block text-base font-semibold">
                      高频 High Frequency HF3
                    </span>
                  ) : type === "4" ? (
                    <></>
                  ) : (
                    <span className="inline-block text-base font-semibold">
                      高频 High Frequency HF1
                    </span>
                  )}
                </div> */}
              <div className="mt-1 flex justify-between px-2">
                <span className="inline-block">
                  <div className=" font-light font-bold text-center">
                    {/* 启动资金 Initiate Capital Amount */}
                    {t("initiatedCapital", sourceKey.highFrequency)}
                  </div>
                  {editMode ? (
                    <div className="">
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder={t(
                          "initiatedCapital",
                          sourceKey.highFrequency
                        )}
                        autoFocus
                        size="large"
                        max={999999999999999}
                        value={capitalAmount}
                        onChange={(e) => {
                          setCapitalAmount(e);
                        }}
                      />
                    </div>
                  ) : (
                    <div className=" font-bold">
                      {formatDecimalNumber(
                        get(
                          profile,
                          `wallet.highFrequency.capital.${exchangePlatform}`
                        ) || 0,
                        numberDecimal
                      )}{" "}
                      USDT
                    </div>
                  )}
                </span>
                <div className="flex">
                  {editMode ? (
                    <div className="">
                      <div
                        className=" text-red-400 pt-1 cursor-pointer font-bold"
                        onClick={() => {
                          setEditMode(false);
                        }}
                      >
                        {t("cancel")}
                      </div>
                      <div
                        className="cursor-pointer font-bold mt-2"
                        style={{ color: "green" }}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {t("submit")}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer font-bold"
                      style={{ color: "#05022c" }}
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      {t("edit")}
                    </div>
                  )}
                </div>
              </div>
              <div className=" flex justify-center items-center mt-4">
                <span
                  className="inline-block w-1/3 mr-2 rounded-2xl"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <div className="text-base font-semibold  text-center">
                    {formatDecimalNumber(
                      get(robotProfile, "robotFundLimit.highFrequency.used") ||
                        0,
                      numberDecimal
                    )}{" "}
                    <br />
                  </div>
                  <div className="text-xs font-light  text-center">
                    {/* 已购买额度 <br /> Used Limit */}
                    {t("usedLimit", sourceKey.highFrequency)}
                  </div>
                </span>
                <span
                  className="inline-block w-1/3 mr-2 rounded-2xl"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  {/* {editMode ? (
                  <div className="my-2">
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="账户额度 Wallet Limit"
                      autoFocus
                      size="large"
                      value={walletLimit}
                      onChange={(e) => {
                        setWalletLimit(e);
                      }}
                    />
                  </div>
                ) : ( */}
                  <div className="text-base font-semibold text-center">
                    {formatDecimalNumber(
                      get(robotProfile, "robotFundLimit.highFrequency.limit") ||
                        0,
                      numberDecimal
                    )}
                    <br />
                  </div>
                  {/* )} */}
                  <div className="text-xs font-light  text-center">
                    {/* 账户额度 <br /> Wallet Limit */}
                    {/* {t("walletLimit", sourceKey.highFrequency)} */}
                    {t("exchangeWallet", sourceKey.highFrequency)}
                  </div>
                </span>

                {/* <span
                className="inline-block w-1/3 mr-2 rounded-2xl"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                }}
              >
                <div className="text-sm font-semibold  text-center">
                  {formatDecimalNumber(exchangeBalance || 0, numberDecimal)}{" "}
                  <br />
                  USDT
                </div>
                <div className="text-xs font-light  text-center">
                  {t("exchangeWallet", sourceKey.highFrequency)}
                </div>
              </span> */}
              </div>

              {/* {editMode ? (
                <div className="grid grid-cols-4 gap-2 items-center mt-7">
                  <div className="col-span-2">
                    <Button
                      className="text-xs rounded input-border mr-2"
                      onClick={() => {
                        setEditMode(false);
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "red",
                        // color: "white"
                      }}
                    >
                      <span className="text-xs text-white">{t("cancel")}</span>
                    </Button>
                  </div>

                  <div className="col-span-2">
                    <Button
                      className="text-xs rounded main-button-color input-border"
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ width: "100%" }}
                    >
                      {t("change")}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center mt-7">
                  <Button
                    className="text-xs rounded main-button-color input-border"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    {t("changeLimit", sourceKey.highFrequency)}
                  </Button>
                </div>
              )} */}
              {/* </MediaQuery> */}
            </div>
            <div className="px-4">
              <div className="px-5">
                <HighFrequencyForm3
                  profile={profile}
                  robotProfile={robotProfile}
                />
                {/* {type === "3" ? (
                  <HighFrequencyForm3 profile={profile} />
                ) : type === "4" ? (
                  <HighFrequencyForm4 profile={profile} />
                ) : (
                  <HighFrequencyForm profile={profile} />
                )} */}
              </div>
            </div>
          </NewHeader>
        </Spin>
        <SelectLanguageModal
          visible={selectVisible}
          onClose={() => {
            setSelectVisible(false);
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  logoutSuccessful,
  clearModal,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
